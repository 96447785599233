import React, { createContext, PropsWithChildren, useEffect, useState } from 'react';
import config from 'config';
import { io, Socket } from 'socket.io-client';

interface SocketContextProps {
  socket: Socket | null;
  isConnected: boolean;
}

export const SocketContext = createContext<SocketContextProps | undefined>(undefined);

export default function SocketProvider({
  children,
}: PropsWithChildren<unknown>) {
    const [socket, setSocket] = useState<Socket | null>(null);
    const [isConnected, setIsConnected] = useState(false);
  
    useEffect(() => {
      const token = localStorage.getItem('accessToken');
  
      const newSocket = io(config.service.WS_URL || 'ws://localhost:8080', {
        autoConnect: true,
        transports: ['websocket'],
        auth: {
          token: token,
        },
      });
  
      setSocket(newSocket);
  
      newSocket.on('connect', () => {
        setIsConnected(true);
        console.log('Connected to WebSocket server');
      });
  
      newSocket.on('disconnect', () => {
        setIsConnected(false);
        console.log('Disconnected from WebSocket server');
      });
  
      return () => {
        newSocket.off('connect');
        newSocket.off('disconnect');
        newSocket.close();
      };
    }, []);
  
    return (
      <SocketContext.Provider value={{ socket, isConnected }}>
        {children}
      </SocketContext.Provider>
    );
};
