import { ITokenResponse } from "@smart-talent/models";
import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from "react";

export enum AuthStates {
  NotLogged,
  Logged,
  TokenExpired,
}

interface AuthContextType {
  getAccessToken: () => string;
  getRefreshToken: () => string;
  setTokens: (tokens: ITokenResponse) => void;
  removeToken: (sessionExpired?: boolean) => void;
  accessToken: any;
  state: AuthStates;
}

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

export const AuthProvider = ({ children }: PropsWithChildren<{}>) => {
  // TODO: Improve after MVP: https://www.rdegges.com/2018/please-stop-using-local-storage/
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("accessToken")
  );
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem("refreshToken")
  );
  const [authState, setAuthState] = useState<AuthStates>(
    accessToken ? AuthStates.Logged : AuthStates.NotLogged
  );

  const memoizedValue = useMemo(
    () => ({
      setTokens: (tokens: ITokenResponse) => {
        setAuthState(AuthStates.Logged);
        setAccessToken(tokens.accessToken);
        setRefreshToken(tokens.refreshToken);
        localStorage.setItem("accessToken", tokens.accessToken);
        localStorage.setItem("refreshToken", tokens.refreshToken);
      },
      removeToken: (sessionExpired = false) => {
        if (sessionExpired) {
          setAuthState(AuthStates.TokenExpired);
          setAuthState(AuthStates.NotLogged);
        }

        if (accessToken) {
          setAccessToken(null);
          setRefreshToken(null);
        }

        if (localStorage.getItem("accessToken")) {
          localStorage.removeItem("accessToken");
        }
      },
      getAccessToken: () => localStorage.getItem("accessToken") ?? "",
      state: authState,
      accessToken: accessToken,

      getRefreshToken: () => localStorage.getItem("refreshToken") ?? "",
    }),
    [accessToken, refreshToken]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw "No Auth context set yet!";
  }

  return authContext;
};
