import { AxiosInstance, AxiosResponse } from "axios";
import config from "../../config";
import { IPaginationResponse, IProject } from "@smart-talent/models";

const GET_PROJECT = "project/";
const GET_PROJECTS_PATH = "project/list";
const CREATE_PROJECT_PATH = "project/add";
const UPDATE_PROJECT_NAME_PATH = "update-title";
const UPDATE_PROJECT_DESCRIPTION_PATH = "update-description";
const DELETE_PROJECT_PATH = "project";

export const getProjects = (
  axiosInstance: AxiosInstance,
  page = 0,
  limit = config.service.QUERY_DEFAULT_LIMIT
): Promise<AxiosResponse<IPaginationResponse<IProject>>> =>
  axiosInstance.get(
    `${config.service.BASE_URL}/${GET_PROJECTS_PATH}?page=${page}&limit=${limit}`
  );

export const createProject = (
  axiosInstance: AxiosInstance,
  projectName: string
): Promise<AxiosResponse<IProject>> =>
  axiosInstance.post(`${config.service.BASE_URL}/${CREATE_PROJECT_PATH}`, {
    name: projectName,
  });

export const updateProjectName = (
  axiosInstance: AxiosInstance,
  projectId: string,
  name: string
): Promise<AxiosResponse<IProject>> =>
  axiosInstance.put(
    `${config.service.BASE_URL}/${GET_PROJECT}${projectId}/${UPDATE_PROJECT_NAME_PATH}`,
    { name: name }
  );

export const updateProjectDescription = (
  axiosInstance: AxiosInstance,
  projectId: string,
  description: string
): Promise<AxiosResponse<IProject>> =>
  axiosInstance.put(
    `${config.service.BASE_URL}/${GET_PROJECT}${projectId}/${UPDATE_PROJECT_DESCRIPTION_PATH}`,
    { description: description }
  );

export const deleteProject = (
  axiosInstance: AxiosInstance,
  projectId: string
): Promise<AxiosResponse<IProject>> =>
  axiosInstance.delete(
    `${config.service.BASE_URL}/${DELETE_PROJECT_PATH}/${projectId}`
  );

export const getProject = (
  axiosInstance: AxiosInstance,
  projectId: string
): Promise<AxiosResponse<IProject>> =>
  axiosInstance.get(`${config.service.BASE_URL}/${GET_PROJECT}${projectId}`);
