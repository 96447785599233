import { useContext } from 'react';
import { AxiosContext } from '../contexts/axiosContext';

export function useAxios() {
  const axiosInstance = useContext(AxiosContext);

  if (!axiosInstance) {
    throw new Error('Axios instance is null');
  }

  return axiosInstance;
}
