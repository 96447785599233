import { AxiosInstance, AxiosResponse } from "axios";
import config from "../../config";
import {
  IPaginationResponse,
  IInterview,
  AddInterviewRequest,
  IGetInterviewByIdResponse,
  ISpeakerMapping,
  PromptChatResponse,
} from "@smart-talent/models";

const GET_INTERVIEW_PATH = "interview/";
const GET_INTERVIEWS_PATH = "interview/list";
const UPDATE_INTERVIEW_PATH = "update-title";
const DELETE_INTERVIEW_PATH = "interview/";
const UPDATE_INTERVIEW_SPEAKERS_PATH = "update-speakers";
const CHAT_PROMPT_PATH = "prompt";
const DOWNLOAD_TRANSCRIPT_PART = "download-transcript";

export const getInterviewsByProjectId = (
  axiosInstance: AxiosInstance,
  page = 0,
  projectId: string,
  limit = config.service.QUERY_DEFAULT_LIMIT,
  title?: string
): Promise<AxiosResponse<IPaginationResponse<IInterview>>> => {
  const params: Record<string, any> = {
    projectId, // Always include projectId
    page,
    limit,
  };

  if (title && title.trim()) {
    params.title = title; // Include title in params only if it's provided
  }

  return axiosInstance.get(
    `${config.service.BASE_URL}/${GET_INTERVIEWS_PATH}`,
    { params: params }
  );
};

export const getInterview = (
  axiosInstance: AxiosInstance,
  interviewId: string
): Promise<AxiosResponse<IGetInterviewByIdResponse>> =>
  axiosInstance.get(
    `${config.service.BASE_URL}/${GET_INTERVIEW_PATH}${interviewId}`
  );

export const addInterview = (
  axiosInstance: AxiosInstance,
  uploadId: string,
  fileKey: string,
  projectId: string,
  uploadedParts: { PartNumber: number; ETag: string }[]
): Promise<AxiosResponse<AddInterviewRequest>> =>
  axiosInstance.post(`${config.service.BASE_URL}/${GET_INTERVIEW_PATH}`, {
    uploadId,
    fileKey,
    projectId,
    uploadedParts,
  });

export const updateInterview = (
  axiosInstance: AxiosInstance,
  interviewId: string,
  title: string
): Promise<AxiosResponse<IInterview>> =>
  axiosInstance.put(
    `${config.service.BASE_URL}/${GET_INTERVIEW_PATH}${interviewId}/${UPDATE_INTERVIEW_PATH}`,
    {
      title: title,
    }
  );

export const deleteInterview = (
  axiosInstance: AxiosInstance,
  interviewId: string
): Promise<AxiosResponse<IInterview>> =>
  axiosInstance.delete(
    `${config.service.BASE_URL}/${DELETE_INTERVIEW_PATH}/${interviewId}`
  );

export const updateInterviewSpeakers = (
  axiosInstance: AxiosInstance,
  interviewId: string,
  speakers: ISpeakerMapping[]
) =>
  axiosInstance.put(
    `${config.service.BASE_URL}/${GET_INTERVIEW_PATH}${interviewId}/${UPDATE_INTERVIEW_SPEAKERS_PATH}`,
    { speakers }
  );

export const promptChat = (
  axiosInstance: AxiosInstance,
  interviewId: string,
  query: string
): Promise<AxiosResponse<PromptChatResponse>> =>
  axiosInstance.post(
    `${config.service.BASE_URL}/${GET_INTERVIEW_PATH}${interviewId}/${CHAT_PROMPT_PATH}`,
    { query }
  );

export const downlaodInterviewTranscript = (
  axiosInstance: AxiosInstance,
  interviewId: string
) =>
  axiosInstance.get(
    `${config.service.BASE_URL}/${GET_INTERVIEW_PATH}${interviewId}/${DOWNLOAD_TRANSCRIPT_PART}`,
    { responseType: "blob" }
  );

//
