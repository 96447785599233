import { createContext, PropsWithChildren, useMemo } from "react";
import axios, { AxiosError, AxiosInstance } from "axios";
import { useAuth } from "./authContext";
import { refreshTokens } from "services/api/auth";
import { ITokenResponse } from "@smart-talent/models";

const BACKEND_REQUEST_ACCESS_TOKEN = "x-access-token";

// const AUTH_FAILED_MESSAGE = "Authentication failed";
const AUTH_FAILED_MESSAGE = "Unauthorized";

export const AxiosContext = createContext<AxiosInstance | null>(null);

export default function AxiosProvider({
  children,
}: PropsWithChildren<unknown>) {
  const auth = useAuth();

  const axiosMemo = useMemo(() => {
    const axiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json",
      },
    });

    axiosInstance.interceptors.request.use((config) => {
      // Read token for anywhere, in this case directly from localStorage
      const token = auth.getAccessToken();
      if (token && config.headers) {
        config.headers[BACKEND_REQUEST_ACCESS_TOKEN] = `${token}`;
      }

      return config;
    });

    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const response = error.response;
        if (error.request.responseURL.toString().includes("refresh-token")) {
          localStorage.removeItem("refreshToken");
          auth.removeToken(true);
          return Promise.reject(error);
        }
        if (
          response.status === 401 &&
          response.data?.message === AUTH_FAILED_MESSAGE &&
          auth.getRefreshToken()
        ) {
          const refreshToken = auth.getRefreshToken();
          localStorage.removeItem("accessToken");
          const response = await refreshTokens(axiosInstance, refreshToken);

          auth.setTokens(response!.data);
          return axiosInstance.request(error.request);
        }
        if (response.status === 401) {
          auth.removeToken(true);
          return Promise.reject(error);
        }

        return Promise.reject(error);
      }
    );

    return axiosInstance;
  }, []);

  return (
    <AxiosContext.Provider value={axiosMemo}>{children}</AxiosContext.Provider>
  );
}
