import { useAxios } from "../useAxios";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  createProject,
  deleteProject,
  getProject,
  getProjects,
  updateProjectDescription,
  updateProjectName,
} from "../../services/api/projects";
import config from "../../config";

const GET_PROJECTS_QUERY_ID = "projects";

export function useGetProjects(limit = config.service.QUERY_DEFAULT_LIMIT) {
  const axios = useAxios();

  return useInfiniteQuery(
    [GET_PROJECTS_QUERY_ID],
    ({ pageParam }) => getProjects(axios, pageParam, limit),
    {
      getNextPageParam: (lastData, pages) => pages.length,
    }
  );
}

export function useCreateProject() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation(
    (args: { projectName: string }) => createProject(axios, args.projectName),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_PROJECTS_QUERY_ID]).then();
      },
    }
  );
}

export function useUpdateProjectName() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation(
    (args: { projectId: string; name: string }) =>
      updateProjectName(axios, args.projectId, args.name),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_PROJECTS_QUERY_ID]).then();
      },
    }
  );
}

export function useUpdateProjectDescription() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation(
    (args: { projectId: string; description: string }) =>
      updateProjectDescription(axios, args.projectId, args.description),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_PROJECTS_QUERY_ID]).then();
      },
    }
  );
}

export function useDeleteProject() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation(
    (args: { projectId: string }) => deleteProject(axios, args.projectId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_PROJECTS_QUERY_ID]).then();
      },
    }
  );
}

export function useGetProject(projectId: string) {
  const axios = useAxios();

  return useQuery(
    [GET_PROJECTS_QUERY_ID, projectId],
    () => getProject(axios, projectId),
    {
      enabled: !!projectId,
    }
  );
}
