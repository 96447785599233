import Link from '@mui/material/Link';
import React, { createContext, useState, useContext, ReactNode } from 'react';
import { toast } from 'react-toastify';

export enum UploadStatus {
  UPLOADING = 'Uploading',
  PROCESSING = 'Proccessing', // When the file is uploaded and is being processed
  COMPLETED = 'Completed', // When the processing is fully complete
  ERROR = 'Error'
}

interface FileUpload {
  fileName: string;
  projectId?: string;
  size?: number;
  uploadId: string;
  toastId?: string;
  progress: number;
  status: UploadStatus;
}

interface UploadContextType {
  files: FileUpload[];
  addFile: (file: FileUpload) => string;
  updateFileProgress: (uploadId: string, progress: number) => void;
  setFileStatus: (uploadId: string, status: UploadStatus, projectId: string, errorMessage?: string, ) => void;
}

const UploadContext = createContext<UploadContextType | undefined>(undefined);

export const useUploadContext = () => {
  const context = useContext(UploadContext);
  if (!context) {
    throw new Error('useUpload must be used within an UploadProvider');
  }
  return context;
};

export const UploadProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [files, setFiles] = useState<FileUpload[]>([]);

  const addFile = (file: FileUpload) => {
    const newFile: FileUpload = {
      fileName: file.fileName,
      projectId: file.projectId,
      size: file.size,
      uploadId: file.uploadId,
      progress: file.progress,
      status: UploadStatus.UPLOADING,
      toastId: file.uploadId,
    };

    setFiles((prevFiles) => [...prevFiles, newFile]);

    toast.info(`Starting upload for ${file.fileName}`, {
      progress: 0,
      autoClose: false,
      toastId: file.uploadId,
    });

    // Return uploadId for further reference
    return file.uploadId;
  };

  const updateFileProgress = (uploadId: string, partProgress: number) => {
    setFiles((prevFiles) => {
      return prevFiles.map((file) => {
        if (file.uploadId === uploadId) {
          const updatedProgress = Math.min(100, (file.progress || 0) + partProgress);
  
          // Update toast
          if (updatedProgress < 100) {
            toast.update(uploadId, {
              progress: updatedProgress / 100,
              autoClose: false,
              render: 
                <>
                  <b>Uploading your file</b><br/>
                  <b>{file.fileName}</b>: {updatedProgress.toFixed(2)}%.
                  <br/> 
                  When complete, you will be notified.
                </>,
            });
          } else {
            toast.update(uploadId, {
              render: 
                <>
                  <b>Upload complete!</b><br/>
                  Your file: <b>{file.fileName}</b> has been uploaded successfully and is now ready to begin processing by our system!
                </>,
              autoClose: false,
            });
          }
  
          return {
            ...file,
            progress: updatedProgress,
          };
        }
        return file;
      });
    });
  };


  const setFileStatus = (uploadId: string, status: UploadStatus, projectId: string, messsage?: string) => {
    setFiles((prevFiles) => {
      return prevFiles.map((file) => {
          let toastMessage = null;

          switch (status) {
            case UploadStatus.PROCESSING:
              toastMessage = <>Your file: <b>{file.fileName}</b> is being processed by our system!</>;
              break;
            case UploadStatus.COMPLETED:
              toastMessage =  
              <>
                <b>Upload complete!</b> <br/>
                Your file: <b>{file.fileName}</b> is ready!{' '}
                <br/>
                <Link 
                  href={`/app/projects/${projectId}/analyze/${uploadId}`}
                  rel="noopener"
                  target="_blank"
                >
                  Click here to analize!
                </Link>
              </>;
              break;
            case UploadStatus.ERROR:
              toastMessage = `Error: ${messsage || 'An error occurred'}`;
              break;
            default:
              return file; // If status is unrecognized, return the file unchanged
          }
  
              toast.info(toastMessage, {
                autoClose: false,
              });
  
          // Return the updated file object with the new status
          return {
            ...file,
            status: status,
          };
      });
    });
  };
  

  return (
    <UploadContext.Provider
      value={{ files, addFile, updateFileProgress, setFileStatus }}
    >
      {children}
    </UploadContext.Provider>
  );
};
