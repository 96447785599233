import "./App.css";
import { history } from "helpers/common";
import { Router } from "react-router-dom";
import ErrorBoundary from "components/ErrorBoundaries";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import theme from "./styles/Theme/Theme";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { AuthProvider } from "./contexts/authContext";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import AxiosProvider from "contexts/axiosContext";
import { MainLayoutProvider } from "contexts/mainLayoutContext";
import { Routes } from "routes/routes";
import { UploadProvider } from "contexts/uploadContext";
import SocketProvider from "contexts/socketContext";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import "react-toastify/dist/ReactToastify.min.css";
import "./styles/Theme/global.styles.scss";
import 'react-chatbot-kit/build/main.css'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <AuthProvider>
      <AxiosProvider>
        <SocketProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CssBaseline enableColorScheme />
              <MainLayoutProvider>
                <UploadProvider>
                  <div className="App">
                    <ToastContainer
                      autoClose={5000}
                      position="top-right"
                      newestOnTop
                      draggable
                    />
                    <Router history={history}>
                      <ErrorBoundary>
                        <Routes />
                      </ErrorBoundary>
                    </Router>
                  </div>
                </UploadProvider>
              </MainLayoutProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </QueryClientProvider>
        </SocketProvider>
      </AxiosProvider>
    </AuthProvider>
  );
}

export default App;
