import { FC } from 'react';

import { useGetInterview } from 'hooks/api/interviews';

interface AnalyzeBreadcrumbsProps {
  match?: any;
}

const AnalizeBreadcrumbs: FC<AnalyzeBreadcrumbsProps> = ({ match }) => {
  const { interviewId } = match?.params ?? '';

  const { data } = useGetInterview(interviewId);

  return <>{data?.data.interview.title}</>;
};

export default AnalizeBreadcrumbs;
