const env = process.env;

const config = {
  deviceType: 'web',
  service: {
    BASE_URL: env.REACT_APP_SERVICE_BASE_URL,
    WS_URL: env.REACT_APP_WS_URL,
    QUERY_DEFAULT_LIMIT: 25,
    ROW_DATETIME_FORMAT: 'MMM D, YYYY',
  }
};

export default config;