import React from "react";
import { FC, FunctionComponent, lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { AuthStates, useAuth } from "../contexts/authContext";
import { toast } from "react-toastify";
import ProjectBreadcrumbs from "components/Breadcrumbs/ProjectBreadcrumbs";
import AnalyzeBreadcrumbs from "components/Breadcrumbs/AnalyzeBreadcrumbs";

const Login = lazy(() => import("pages/Auth/Login"));
const SignUp = lazy(() => import("pages/Auth/SignUp"));
const ResetPassword = lazy(() => import("pages/Auth/ResetPassword"));
const ForgotPassword = lazy(() => import("pages/Auth/ForgotPassword"));

const MainLayout = lazy(() => import("layouts/MainLayout"));
const Home = lazy(() => import("pages/home"));
const Projects = lazy(() => import("pages/Projects"));
const ProjectDashboard = lazy(() => import("pages/ProjectDashboard"));
const Analyze = lazy(() => import("pages/Analysis"));
const Settings = lazy(() => import("pages/Settings"));
const PersonalSettings = lazy(() => import("pages/PersonalSettings"));

type routeItem = {
  path: string;
  key: string;
  exact: boolean;
  breadcrumb?: FunctionComponent<any> | string;
  component: FunctionComponent<any> | JSX.Element;
};

type routes = routeItem & {
  routes?: routeItem[];
};

const AuthLayout: FC<JSX.IntrinsicAttributes & { routes: routes[] }> = (
  props
) => {
  const { state, accessToken } = useAuth();

  if (!accessToken) {
    if (state === AuthStates.TokenExpired) {
      toast.error("Session expired. Please login again.", {
        toastId: "session-expired",
      });
    }

    /*    if (state === AuthStates.NotLogged && getInviteId()) {
      toast.success('You need to sign in first to accept the invitation!', { toastId: 'session-expired' });
    } */

    if (state === AuthStates.NotLogged) {
      toast.success("Logged out successfully, see you soon!", {
        toastId: "session-expired",
      });
    }

    return <Redirect to={"/"} />;
  }

  /* const useInvitationToTeam = (
    <>
      <Redirect to={`app/settings/teams?id=${getInviteId()}`} />
    </>
  ); */

  return (
    <MainLayout>
      <Suspense
        fallback={
          <Grid
            alignItems="center"
            container
            direction="column"
            justifyContent="center"
            sx={{ height: "100%" }}
          >
            <CircularProgress />
          </Grid>
        }
      >
        <RenderRoutes {...props} />
        {/*    {getInviteId() && useInvitationToTeam} */}
      </Suspense>
    </MainLayout>
  );
};

const RootRedirector: FC = () => {
  const { accessToken } = useAuth();

  if (!accessToken) {
    return <Redirect to={"/login"} />;
  }
  return <Redirect to={"/app"} />;
};

const ROUTES: routes[] = [
  {
    path: "/",
    key: "ROOT",
    exact: true,
    component: RootRedirector,
    routes: [],
  },
  {
    path: "/login",
    key: "LOGIN",
    exact: true,
    component: Login,
    routes: [],
  },
  {
    path: "/sign-up",
    key: "SIGNUP",
    exact: true,
    component: SignUp,
    routes: [],
  },
  {
    path: "/forgot-password",
    key: "FORGOT_PASS",
    exact: true,
    component: ForgotPassword,
    routes: [],
  },
  {
    path: "/reset-password",
    key: "RESET_PASS",
    exact: true,
    component: ResetPassword,
    routes: [],
  },
  {
    path: "/app",
    exact: false,
    key: "APP",
    component: AuthLayout,
    routes: [
      {
        path: "/app",
        key: "APP_ROOT",
        exact: true,
        component: Home,
      },
      {
        path: "/app/projects",
        key: "PROJECTS",
        exact: true,
        breadcrumb: "Projects",
        component: Projects,
      },
      {
        path: "/app/projects/:projectId/analyze/:interviewId",
        key: "ANALYZE",
        exact: false,
        breadcrumb: AnalyzeBreadcrumbs,
        component: Analyze,
      },
      {
        path: "/app/projects/:projectId",
        key: "PROJECT_DASHBOARD",
        exact: false,
        breadcrumb: ProjectBreadcrumbs,
        component: ProjectDashboard,
      },
      {
        path: "/app/settings",
        key: "SETTINGS",
        breadcrumb: "Settings",
        exact: true,
        component: Settings,
      },
      {
        path: '/app/settings/personal',
        key: 'PERSONAL_SETTINGS',
        breadcrumb: 'Personal settings',
        exact: true,
        component: PersonalSettings
      },
    ],
  },
];

export default ROUTES;

export function RenderRoutes({ routes }: { routes: routes[] }) {
  return (
    <Switch>
      {routes.map(({key, ...rest}) => {
        // eslint-disable-next-line react/jsx-key
        return <RouteWithSubRoutes key={key} {...rest}/>;
      })}

      <Route component={() => <h1>Not Found!</h1>} />
    </Switch>
  );
}

function RouteWithSubRoutes(route: routes) {
  const Component = route.component as React.ElementType;
  return (
    <Route
      key={route.key}
      exact={route.exact}
      path={route.path}
      render={(props) => <Component {...props} routes={route.routes} />}
    />
  );
}
