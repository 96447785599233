import { AxiosInstance, AxiosResponse } from "axios";
import { IJwtUser, INewUser, ITokenResponse } from "@smart-talent/models";
import config from "config";

const SIGN_IN_PATH = "auth/signin";
const SIGN_UP_PATH = "auth/signup";
const CURRENT_USER_PATH = "auth/me";
const REFRESH_TOKEN_PATH = "auth/refresh-tokens";
const UPDATE_USER_PATH = "auth/updateProfile"; //Not implemented on BE
const UPDATE_PASSWORD = "auth/updatePassword"; //Not implemented on BE
const FORGOT_PASSWORD = "auth/initiate-reset-password";
const RESET_PASSWORD = "auth/reset-password ";

export type UserSignUpType = INewUser & {
  confirmPassword: string;
};
export const signIn = (
  axiosInstance: AxiosInstance,
  username: string,
  password: string
) =>
  axiosInstance.post<ITokenResponse>(
    `${config.service.BASE_URL}/${SIGN_IN_PATH}`,
    {
      username,
      password,
    }
  );

export const signUp = (
  axiosInstance: AxiosInstance,
  userInfo: UserSignUpType
) => axiosInstance.post(`${config.service.BASE_URL}/${SIGN_UP_PATH}`, userInfo);

export const refreshTokens = (
  axiosInstance: AxiosInstance,
  refreshToken: string
) =>
  axiosInstance.post<ITokenResponse>(
    `${config.service.BASE_URL}/${REFRESH_TOKEN_PATH}`,
    {
      refreshToken,
    }
  );

export const getCurrentUser = (
  axiosInstance: AxiosInstance
): Promise<AxiosResponse<IJwtUser>> =>
  axiosInstance.get(`${config.service.BASE_URL}/${CURRENT_USER_PATH}`);

export const updateUser = (
  axiosInstance: AxiosInstance,
  id: string,
  name: string,
  surname: string,
  email: string
) =>
  axiosInstance.post(`${config.service.BASE_URL}/${UPDATE_USER_PATH}`, {
    id,
    name,
    surname,
    email,
  });

export const updatePassword = (
  axiosInstance: AxiosInstance,
  clientId: string,
  oldPassword: string,
  newPassword: string,
  confirmPassword: string
) =>
  axiosInstance.post(`${config.service.BASE_URL}/${UPDATE_PASSWORD}`, {
    clientId,
    oldPassword,
    newPassword,
    confirmPassword,
  });

export const forgotPassword = (axiosInstance: AxiosInstance, email: string) =>
  axiosInstance.post(`${config.service.BASE_URL}/${FORGOT_PASSWORD}`, {
    email,
  });

export const resetPassword = (
  axiosInstance: AxiosInstance,
  password: string,
  repeatPassword: string,
  token: string | null,
  email: string | null
) =>
  axiosInstance.put(`${config.service.BASE_URL}/${RESET_PASSWORD}`, {
    password,
    repeatPassword,
    token,
    email,
  });
