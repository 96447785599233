import { useAxios } from "../useAxios";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  addInterview,
  deleteInterview,
  downlaodInterviewTranscript,
  getInterview,
  getInterviewsByProjectId,
  promptChat,
  updateInterview,
  updateInterviewSpeakers,
} from "../../services/api/interviews";
import config from "../../config";
import { ISpeakerMapping } from "@smart-talent/models";

const GET_INTERVIEWS_QUERY_ID = "inteview";

export function useGetInterviewsByProjectId(
  projectId: string,
  limit = config.service.QUERY_DEFAULT_LIMIT,
  searchVal?: string
) {
  const axios = useAxios();

  return useInfiniteQuery(
    [GET_INTERVIEWS_QUERY_ID, projectId, searchVal],
    ({ pageParam }) =>
      getInterviewsByProjectId(axios, pageParam, projectId, limit, searchVal),
    {
      getNextPageParam: (lastData, pages) => pages.length,
      enabled: !!projectId,
      keepPreviousData: true,
    }
  );
}

export function useGetInterview(interviewId: string) {
  const axios = useAxios();
  const interview = useQuery(
    [GET_INTERVIEWS_QUERY_ID, interviewId],
    () => getInterview(axios, interviewId),
    {
      enabled: !!interviewId,
    }
  );
  return interview;
}

export const useAddInterview = () => {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation(
    (args: {
      uploadId: string;
      fileKey: string;
      projectId: string;
      uploadedParts: { PartNumber: number; ETag: string }[];
    }) =>
      addInterview(
        axios,
        args.uploadId,
        args.fileKey,
        args.projectId,
        args.uploadedParts
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_INTERVIEWS_QUERY_ID]).then();
      },
    }
  );
};

export function useUpdateInterview() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation(
    (args: { interviewId: string; title: string }) =>
      updateInterview(axios, args.interviewId, args.title),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_INTERVIEWS_QUERY_ID]).then();
      },
    }
  );
}

export function useDeleteInterview() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation(
    (args: { interviewId: string }) => deleteInterview(axios, args.interviewId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_INTERVIEWS_QUERY_ID]).then();
      },
    }
  );
}

export function useUpdateInterviewSpeakers() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation(
    (args: { interviewId: string; speakers: ISpeakerMapping[] }) =>
      updateInterviewSpeakers(axios, args.interviewId, args.speakers),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_INTERVIEWS_QUERY_ID]).then();
      },
    }
  );
}

export const usePromptChat = () => {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation(
    (args: { interviewId: string; query: string }) =>
      promptChat(axios, args.interviewId, args.query),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_INTERVIEWS_QUERY_ID]).then();
      },
    }
  );
};

export const useDownloadInterviewTranscript = () => {
  const axios = useAxios();

  return useMutation((args: { interviewId: string }) =>
    downlaodInterviewTranscript(axios, args.interviewId)
  );
};
