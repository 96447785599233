import { FC } from 'react';
import { useGetProject } from '../../../hooks/api/projects';

interface ProjectBreadcrumbsProps {
  match?: any;
}

const ProjectBreadcrumbs: FC<ProjectBreadcrumbsProps> = ({ match }) => {
  const { projectId } = match?.params ?? '';

  const { data } = useGetProject(projectId);

  return <>{data?.data.name}</>;
};

export default ProjectBreadcrumbs;
